import { useState } from "react";
import { GrFormClose } from "react-icons/gr";

export default function Modal({
  state,
  toggle,
  title,
  price,
  description,
  image,
}) {
  const [name, setName] = useState("");
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleSend = () => {
    const whatsappLink = `https://wa.me/+2347067126290?text=Hi%i'm%20interested%20in%20${title}`;

    // Open the WhatsApp link in a new tab
    window.open(whatsappLink, "_blank");
  };

  return (
    <>
      {state && (
        <div
          onClick={() => toggle()}
          className='w-full z-[40] p-4 bg-[#000000ab] h-screen overflow-hidden fixed top-0 left-0'
        >
          <div
            onClick={stopPropagation}
            className='z-[60] bg-base-100 space-y-6 p-4 bg-white h-full max-w-5xl mx-auto overflow-hidden'
          >
            <div className='flex items-center justify-between bg-white'>
              <h1 className='font-bold text-xl'>Product</h1>
              <button onClick={() => toggle()}>
                <GrFormClose size={25} />
              </button>
            </div>

            <div className='relative grid grid-cols-1 md:grid-cols-2 gap-6 items-center pb-10 h-full overflow-auto'>
              <img
                src={image}
                alt='image'
                className='w-full h-80 md:h-full object-cover object-top'
              />
              <div className='flex flex-col gap-6'>
                <div>
                  <h1 className='text-2xl md:text-3xl font-bold mb-4'>
                    {title}
                  </h1>
                  <span className='text-orange-500'>{price}</span>
                </div>
                <p className='max-w-xl'>{description}</p>
                <button
                  onClick={handleSend}
                  className='md:w-fit w-full text-center bg-black text-white px-8 py-4 font-bold hover:bg-white hover:text-black hover:ease-in duration-200'
                >
                  Book
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
