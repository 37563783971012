import React from "react";
import { Navbar } from "../components";
import Logo from "../assets/404.jpg";
import { motion } from "framer-motion";

const ComingSoon = () => {
  return (
    <>
      <Navbar />
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className='h-auto px-4 flex flex-col items-center justify-center'
      >
        <h1 className='font-bold text-center mt-28 text-2xl md:text-[3rem]'>
          COMING SOON
        </h1>
        <img src={Logo} alt='image' className='w-[350px]' />
        <button className='bg-orange-600 mt-1 text-white px-8 py-4 font-bold hover:bg-black hover:text-white hover:ease-in duration-200'>
          Discover
        </button>
      </motion.div>
    </>
  );
};

export default ComingSoon;
