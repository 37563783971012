import { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Poy from "../assets/poy.jpg";
import Urban from "../assets/urban.jpg";
import { Categories, Footer, Modal, Navbar } from "../components";
import Data from "../items.json";

export default function Home() {
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    scroll.scrollToTop(); // Scroll to the top of the page
  }, []);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };
  return (
    <>
      <Navbar />

      {/* HERO SECTION */}
      <div className='overflow-hidden w-full'>
        <Zoom
          scale={1.4}
          indicators={false}
          duration={5000}
          arrows={false}
          autoplay={true}
          canSwipe={false}
        >
          <div
            style={{
              background: `linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(${Poy})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            className='h-screen w-full flex items-center justify-center'
          >
            <div className='text-center gap-8 flex flex-col items-center'>
              <div>
                <h1 className='font-bold mb-4 text-3xl md:text-6xl text-white uppercase'>
                  Setting the Trend
                </h1>
                <span className='font-medium text-lg md:text-2xl text-orange-500'>
                  Kaftan
                </span>
              </div>
              <a
                href='/natives'
                className='bg-black text-white px-8 py-4 font-bold hover:bg-white hover:text-black hover:ease-in duration-200'
              >
                Shop Now
              </a>
            </div>
          </div>

          {/* 2 */}
          <div
            style={{
              background: `linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(${Urban})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            className='h-screen w-full flex items-center justify-center'
          >
            <div className='text-center gap-8 flex flex-col items-center'>
              <div>
                <h1 className='font-bold mb-4 text-3xl md:text-6xl text-white uppercase'>
                  Setting the Trend
                </h1>
                <span className='font-medium text-lg md:text-2xl text-orange-500'>
                  Trad
                </span>
              </div>
              <a
                href='/natives'
                className='bg-black text-white px-8 py-4 font-bold hover:bg-white hover:text-black hover:ease-in duration-200'
              >
                Shop Now
              </a>
            </div>
          </div>
        </Zoom>
      </div>

      {/* CATEGORIES */}
      <Categories />

      {/* PRODUCTS */}
      <div className='flex flex-col gap-20 md:gap-32 py-32'>
        <div className='md:px-0 px-4 text-center mx-auto max-w-7xl flex flex-col gap-8 items-center'>
          <h1 className='font-bold text-2xl'>KAFTAN</h1>

          <div className='grid grid-cols-2 md:grid-cols-4 item-center justify-center gap-4'>
            {Data.map((post) => {
              return (
                <div
                  key={post.id}
                  onClick={() => handleProductClick(post)}
                  className='space-y-4 text-left hover:cursor-pointer'
                >
                  <div className='overflow-hidden'>
                    <img
                      src={post.image}
                      alt={post.imagealt}
                      className='w-96 h-52 md:h-72 object-cover hover:scale-125 ease-in duration-300 hover:block'
                    />
                  </div>
                  <div>
                    <p className='uppercase'>{post.name}</p>
                    <p className='text-orange-600 text-sm'>{post.price}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <a
            href='/natives'
            className='bg-black md:w-fit w-full text-white px-8 py-4 font-bold hover:bg-orange-500 hover:text-black hover:ease-in duration-200'
          >
            Load More
          </a>
        </div>

        <div className='md:px-0 px-4 text-center mx-auto max-w-7xl flex flex-col gap-8 items-center'>
          <h1 className='font-bold text-2xl'>SUITS</h1>

          <div className='grid grid-cols-2 md:grid-cols-4 item-center justify-center gap-4'>
            {Data.map((post) => {
              return (
                <div
                  key={post.id}
                  onClick={() => handleProductClick(post)}
                  className='space-y-4 text-left hover:cursor-pointer'
                >
                  <div className='overflow-hidden'>
                    <img
                      src={post.image}
                      alt={post.imagealt}
                      className='w-96 h-52 md:h-72 object-cover hover:scale-125 ease-in duration-300 hover:block'
                    />
                  </div>
                  <div>
                    <p className='uppercase'>{post.name}</p>
                    <p className='text-orange-600 text-sm'>{post.price}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <a
            href='/suits'
            className='bg-black md:w-fit w-full text-white px-8 py-4 font-bold hover:bg-orange-500 hover:text-black hover:ease-in duration-200'
          >
            Load More
          </a>
        </div>

        {/* COLLECTION */}
        <div
          style={{
            background: `linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(${Urban})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
          className=' text-white text-center h-full px-4 py-32 '
        >
          <div className='max-w-5xl mx-auto flex flex-col justify-center items-center gap-8'>
            <h1 className='font-bold  text-2xl'>CLASSIC COLLECTION</h1>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Non a
              impedit adipisci reiciendis voluptatem nobis voluptatibus commodi
              maxime excepturi rem sapiente, ipsum sed! Hic assumenda, sint
              voluptas sequi sit labore?
            </span>
            <a
              href='/collection'
              className='border-white border text-white px-8 py-2 mt-3 font-bold hover:bg-white hover:text-black'
            >
              Discover
            </a>
          </div>
        </div>

        <div className='px-4 space-y-8'>
          <h2 className='font-bold text-2xl text-center uppercase'>Address</h2>
          <iframe
            title='map'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.6028181920465!2d3.368061371926875!3d6.571707808086447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b9279689fb88d%3A0xb728b7f8fff68d8d!2s12%20Salami%20St%2C%20Mende%2C%20Lagos%20105102%2C%20Lagos!5e0!3m2!1sen!2sng!4v1702980307425!5m2!1sen!2sng'
            className='w-full h-96'
            allowfullscreen=''
            loading='lazy'
            referrerpolicy='no-referrer-when-downgrade'
          ></iframe>
        </div>
      </div>

      <Footer />

      {selectedProduct && (
        <Modal
          title={selectedProduct.name}
          state={true}
          toggle={handleCloseModal}
          image={selectedProduct.image}
          price={selectedProduct.price}
          description={selectedProduct.description}
        />
      )}
    </>
  );
}
