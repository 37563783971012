import { useState } from "react";
import Data from "../items.json";
import { Footer, Modal, Navbar } from "../components";
import Poy from "../assets/poy.jpg";

export default function Shirts() {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };
  return (
    <>
      <Navbar />
      <div
        style={{
          background: `linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(${Poy})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
        className='h-full pt-48 pb-20 w-full flex items-end justify-center'
      >
        <div className='text-center gap-8 flex flex-col items-center'>
          <div>
            <h1 className='font-bold mb-4 text-3xl md:text-6xl text-white uppercase'>
              Shirts
            </h1>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-20 md:gap-32 my-24 md:px-0 px-4'>
        <div className='max-w-7xl mx-auto'>
          <div className='grid grid-cols-2 md:grid-cols-4 gap-4 items-center justify-center'>
            {Data.map((post) => {
              return (
                <div
                  key={post.id}
                  onClick={() => handleProductClick(post)}
                  className='space-y-4 text-left hover:cursor-pointer'
                >
                  <div className='overflow-hidden'>
                    <img
                      src={post.image}
                      alt={post.imagealt}
                      className='w-96 h-52 md:h-72 object-cover hover:scale-125 ease-in duration-300 hover:block'
                    />
                  </div>
                  <div>
                    <p className='uppercase'>{post.name}</p>
                    <p className='text-orange-600 text-sm'>{post.price}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className='px-4 space-y-8'>
          <h2 className='font-bold text-2xl text-center uppercase'>Address</h2>
          <iframe
            title='map'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.6028181920465!2d3.368061371926875!3d6.571707808086447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b9279689fb88d%3A0xb728b7f8fff68d8d!2s12%20Salami%20St%2C%20Mende%2C%20Lagos%20105102%2C%20Lagos!5e0!3m2!1sen!2sng!4v1702980307425!5m2!1sen!2sng'
            className='w-full h-96'
            allowfullscreen=''
            loading='lazy'
            referrerpolicy='no-referrer-when-downgrade'
          ></iframe>
        </div>
      </div>

      <Footer />

      {selectedProduct && (
        <Modal
          title={selectedProduct.name}
          state={true}
          toggle={handleCloseModal}
          image={selectedProduct.image}
          price={selectedProduct.price}
          description={selectedProduct.description}
        />
      )}
    </>
  );
}
