import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Categories() {
  const { ref, inView } = useInView();
  const animation = useAnimation();

  useEffect(() => {
    console.log("use effect hook, inView =", inView);
    if (inView) {
      animation.start({
        y: 0,
        transition: {
          type: "slide",
          duration: 2,
          easing: 0.6,
        },
      });
    }
    if (!inView) {
      animation.start({ y: "-100vh" });
    }
  }, [inView]);

  return (
    <div ref={ref} className='grid grid-cols-1 md:grid-cols-2 h-screen'>
      <motion.a
        animate={animation}
        href='/suits'
        className='bg-classic bg-top bg-no-repeat bg-cover w-full'
      >
        <div className='z-index-1 w-full h-full text-white flex justify-center md:justify-start items-center md:items-end p-4 md:p-8 bg-[#0000006c] hover:bg-white/5 hover:underline'>
          <h1 className='font-bold'>CLASSIC OUTFIT</h1>
        </div>
      </motion.a>
      <motion.a
        animate={animation}
        href='/natives'
        className='bg-trad bg-top bg-no-repeat bg-cover w-full'
      >
        <div className='z-index-1 w-full h-full text-white flex justify-center md:justify-start items-center md:items-end p-4 md:p-8 bg-[#0000006c] hover:bg-white/5 hover:underline'>
          <h1 className='font-bold'>TRADITIONAL OUTFIT</h1>
        </div>
      </motion.a>
      <motion.a
        animate={animation}
        href='/shirts'
        className='col-span-1 md:col-span-2 bg-urban bg-top bg-no-repeat bg-cover w-full'
      >
        <div className='z-index-1 w-full h-full text-white flex justify-center md:justify-start items-center md:items-end p-4 md:p-8 bg-[#0000006c] hover:bg-white/5 hover:underline'>
          <h1 className='font-bold'>URBAN OUTFIT</h1>
        </div>
      </motion.a>
    </div>
  );
}
