import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className='flex flex-col items-center md:flex-row justify-between gap-10 px-4 py-10 bg-gray-100 text-sm'>
      <ul className=' flex flex-col md:flex-row items-center gap-4'>
        <img src='Logo2.svg' alt='logo' className='w-24' />
        <Link to='https://www.facebook.com/DelmurphOlakunmi'>Facebook</Link>
        <Link to='https://www.instagram.com/delmurph_scissors'>Instagram</Link>
        <a href='tel:+2347067126290'>Contact</a>
      </ul>
      <p>© 2023. Delmurph Scissors</p>
    </footer>
  );
}
