import React, { useState } from "react";
import { AiFillInstagram, AiFillPhone, AiOutlineMenu } from "react-icons/ai";
import { HiLocationMarker } from "react-icons/hi";
import { IoLogoFacebook } from "react-icons/io";
import { MdOutlineClose } from "react-icons/md";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 200) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <div
        className={`fixed z-10 top-0 w-full px-3 py-4 space-y-4 ${
          navbar ? " text-black bg-white " : " text-white"
        }`}
      >
        <div className='flex justify-between items-center'>
          <div className='flex items-center gap-4'>
            <Link
              className='hidden md:block'
              to='https://www.instagram.com/delmurph_scissors'
            >
              <AiFillInstagram />
            </Link>
            <Link
              className='hidden md:block'
              to='https://www.facebook.com/DelmurphOlakunmi'
            >
              <IoLogoFacebook />
            </Link>
            <button onClick={toggleMenu} className='block md:hidden'>
              <AiOutlineMenu />
            </button>
          </div>

          <a href='/'>
            {navbar ? (
              <img src='Logo1.svg' alt='logo' className='w-24 md:w-40' />
            ) : (
              <img src='Logo3.svg' alt='logo' className='w-24 md:w-40' />
            )}
          </a>

          <div className='flex items-center gap-4'>
            <a href='https://goo.gl/maps/da4BgJ3Rd8SyY63r5' target='blank'>
              <HiLocationMarker />
            </a>
            <a className='md:block hidden' href='tel:+2347067126290'>
              <AiFillPhone />
            </a>
          </div>
        </div>
        <nav className='hidden md:block'>
          <ul className='flex gap-16 justify-start sm:justify-center text-sm items-center px-5 flex-nowrap overflow-x-scroll snap-x-mandatory'>
            <Link to='/suits' className='hover:underline underline-offset-4'>
              SUITS
            </Link>
            <Link to='/natives' className='hover:underline underline-offset-4'>
              NATIVES
            </Link>
            <Link to='/shirts' className='hover:underline underline-offset-4'>
              SHIRTS
            </Link>
            <Link to='/pants' className='hover:underline underline-offset-4'>
              PANTS
            </Link>
          </ul>
        </nav>
      </div>
      {showMenu && (
        <div
          onClick={toggleMenu}
          className='w-full h-full pt-8 z-[40] px-4 bg-black text-white fixed top-0 left-0'
        >
          <div
            onClick={stopPropagation}
            className='z-[60] bg-base-100 space-y-8 p-4'
          >
            <div className='flex items-center justify-between'>
              <img src='Logo3.svg' alt='logo' className='w-24' />
              <button onClick={toggleMenu}>
                <MdOutlineClose color='white' size={25} />
              </button>
            </div>
            <ul className='flex flex-col gap-6'>
              <Link to='/' className='py-4 border-b border-white'>
                Home
              </Link>
              <Link to='/suits' className='py-4 border-b border-white'>
                SUITS
              </Link>
              <Link to='/natives' className='py-4 border-b border-white'>
                NATIVES
              </Link>
              <Link to='/shirts' className='py-4 border-b border-white'>
                SHIRTS
              </Link>
              <Link to='/pants' className='py-4 border-b border-white'>
                PANTS
              </Link>
            </ul>
            <div className='flex items-center justify-center gap-4'>
              <Link to='https://www.instagram.com/delmurph_scissors'>
                <AiFillInstagram size={20} />
              </Link>
              <Link to='https://www.facebook.com/DelmurphOlakunmi'>
                <IoLogoFacebook size={20} />
              </Link>
              <a href='tel:+2347067126290'>
                <AiFillPhone size={20} />
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
